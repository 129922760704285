import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import InsightsContent from "../molecules/InsightsPage/InsightsContent";
import InsightsHeader from "../molecules/InsightsPage/insightsHeader";

const insights = () => {
  return (
    <Layout isNavWhite={true} hideMap hideFAQ>
      <SEO
        canonicalLink={"https://www.goken-global.com/insights/"}
        title={"Insights Hub: Insights by Goken"}
        description={
          "Explore Goken Global's insights on industry trends, engineering innovations, and technology advancements."
        }
        pageName={"Insights"}
        // meta="Goken Blogs, case studies, engineering case studies, automotive case studies, staffing case studies, training case studies"
        facebookDescription={
          "Goken Engineering Services Insights provides case studies, news, and articles or blogs which we try to show and explore the best resources for you."
        }
        facebookTitle={
          "Goken | Engineering Services Insights - Case Studies | News | Blogs"
        }
        keywords={"engineering services"}
      ></SEO>
      <InsightsHeader
        heading="FIND THE BEST RESOURCES FOR YOU"
        bgImageClass="bg-image-insights-page"
      ></InsightsHeader>
      <InsightsContent></InsightsContent>
    </Layout>
  );
};

export default insights;
